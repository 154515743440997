<template>
  <apexchart type="donut" :options="chartOptions" :series="series"></apexchart>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  components: { apexchart: VueApexCharts },
  data() {
    let series = [50, 45,24,65,20];
    let chartOptions = {
      chart: {
        type: "donut",
      },
      title:{
        text:"Sales by Produce Line"
      },
      labels: ["Coffee", "Garden Egg","Maize","Chilli","Others"],
      legend: {
        position: "bottom",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    };
    return {
      series,
      chartOptions,
    };
  },
};
</script>

<style>
</style>