<template>
     <apexchart type="line" height="200" :options="chartOptions" :series="series"></apexchart>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
 components: {
    apexchart: VueApexCharts,
  },
  data() {
    let series = [
      {
        name: "M",
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
      },
    ];
    let chartOptions = {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
        ],
      }
    };
    return {
      series,
      chartOptions
    };
  },
};
</script>

<style>
</style>