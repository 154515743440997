<template>
  <div>
    <b-row>
      <b-col cols="9">
        <div class="mb-2">
          <b-dropdown text="01/05/20 - 30/08/20" class="m-md-2" variant="light">
            <b-dropdown-item>Last Month</b-dropdown-item>
          </b-dropdown>
          <div class="d-flex align-items-center">
            <div class="col-5 border-right">
              <strong class="text-muted">Produce Sales</strong>
              <h2>UGX 580M</h2>
              <pie-chart></pie-chart>
            </div>
            <div class="col-7">
              <div class="d-flex">
                <div class="col-8">
                  <strong class="text-muted"
                    >Monthly Sales (All produce)</strong
                  >
                  <h6 class="text-primary">Avg Sales</h6>
                  <h2>UGX 120 M/ month</h2>
                </div>
                <div class="col-4 align-self-bottom">
                  <b-dropdown
                    text="All produce lines"
                    class="m-md-2"
                    variant="light"
                  >
                    <b-dropdown-item>Coffee</b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <line-chart></line-chart>
            </div>
          </div>
        </div>
        <hr style="background-color:#e2e2e2;" class="my-0 py-0"/>
        <div class="d-flex align-items-center">
            <div class="col-5 border-right">
                <pie-chart-1></pie-chart-1>
            </div>
            <div class="col-7">
                <bar-graph></bar-graph>
            </div>
        </div>
      </b-col>
      <b-col cols="3">
          <div>
              <h6 class="text-muted">Total Sales</h6>
              <h2 class="font-weight-bold">UGX 580M</h2>
              <h4>~ $161,000</h4>
          </div>
          <div class="mt-2">
              <h6 class="text-muted">Quaterly</h6>
              <h2 class="font-weight-bold">UGX 408M</h2>
              <div class="text-success"> <b-icon-caret-up></b-icon-caret-up> 12% increase from 2019</div>
          </div>
          <div class="mt-2">
              <h6 class="text-muted">Gross Profit</h6>
              <h2 class="text-success"> UGX 408M</h2>
          </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PieChart from "./components/PieCharts.vue";
import LineChart from "./components/LineChart.vue";
import PieChart1 from "./components/PieCharts1.vue";
import BarGraph from './components/BarGraph.vue';
export default {
  components: { PieChart, LineChart,PieChart1, BarGraph },
};
</script>

<style>
</style>