<template>
  <apexchart type="bar" height="200" width="500" :options="chartOptions" :series="series"></apexchart>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: { apexchart: VueApexCharts },
  data() {
    let series = [
      {
        data: [400, 430, 448, 350, 240],
      },
    ];
    let chartOptions = {
      chart: {
        type: "bar",
        height: 350,
      },
      title:{
        text:"Revenue from buyers"
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [
          "Yali Investments",
          "Aseel Impex",
          "Biyinzika Poultry",
          "Farmers/Input"
        ],
      },
    };
    return { series, chartOptions };
  },
};
</script>

<style>
</style>