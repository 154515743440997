<template>
  <apexchart type="donut" :options="chartOptions" :series="series"></apexchart>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  components: { apexchart: VueApexCharts },
  data() {
    let series = [50, 45];
    let chartOptions = {
      chart: {
        type: "donut",
      },
      title:{
        text:"Revenue Sources"
      },
      labels: ["Farm Inputs", "Produce"],
      legend: {
        position: "bottom",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    };
    return {
      series,
      chartOptions,
    };
  },
};
</script>

<style>
</style>